<template>
  <div>
    <v-card>
      <v-card-title>
        Academies
        <v-spacer />
        <v-btn v-if="isAdmin" color="primary" dark @click="showAddDialog">Add New Academy</v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" offset-md="8">
            <v-text-field
              v-model="filter"
              :append-icon="icons.mdiMagnify"
              label="Search Academies"
              single-line
              hide-details
              dense
              outlined
              @input="filterAcademies"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="filteredAcademies"
        :loading="isLoading"
        :items-per-page="10"
        class="elevation-1"
      >
        <template #item.actions="{ item }">
          <div class="d-flex justify-end" style="max-width: 150px">
            <v-icon class="mr-2" @click="goToAcademy(item)">
              {{ icons.mdiEye }}
            </v-icon>
            <v-icon class="mr-2" @click="showEditDialog(item)">
              {{ icons.mdiPencilOutline }}
            </v-icon>
            <v-icon @click="showDeleteConfirmationDialog(item)">
              {{ icons.mdiDeleteOutline }}
            </v-icon>
          </div>
        </template>
      </v-data-table>

      <academy-edit-dialog
        v-model="isEditDialogVisible"
        :item="currentAcademy"
        :is-loading="isLoading"
        @save="saveAcademy"
        @cancel="closeDialog"
      />

      <delete-dialog
        v-model="isDeleteDialogVisible"
        :item="currentAcademy"
        :is-loading="isLoading"
        @cancel="onDeleteCanceled"
        @delete="onDeleteConfirmed"
      />
    </v-card>
  </div>
</template>

<script>
import { mdiMagnify, mdiEye, mdiPencilOutline, mdiDeleteOutline } from '@mdi/js'
import { ref, computed, onMounted } from 'vue'
import useAcademies from '@/composables/api/academies'
import useAuth from '@/composables/useAuth'
import { useRouter } from '@/utils/index'
import AcademyEditDialog from '@/views/academies/partials/AcademyEditDialog.vue'
import DeleteDialog from '@/components/DeleteDialog.vue'

export default {
  components: {
    AcademyEditDialog,
    DeleteDialog,
  },
  setup() {
    const { academies, isLoading, fetchAcademies, createAcademy, updateAcademy, deleteAcademy } = useAcademies()
    const { hasRole } = useAuth()
    const { router } = useRouter()

    const filter = ref('')
    const filteredAcademies = ref([])
    const isEditDialogVisible = ref(false)
    const isDeleteDialogVisible = ref(false)
    const currentAcademy = ref({
      name: '',
      address: {
        street: '',
        houseNo: '',
        zipCode: '',
        city: '',
        country: '',
      },
      telephone: '',
      fax: '',
      email: '',
      taxNumber: '',
      taxNumberEu: '',
    })

    const isAdmin = computed(() => hasRole(['ADMIN']))

    const headers = [
      { text: 'Name', value: 'name' },
      { text: 'ID', value: 'id' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'end', width: '150px' },
    ]

    const filterAcademies = () => {
      const search = filter.value ? filter.value.toLowerCase() : ''
      if (search === '') {
        filteredAcademies.value = academies.value
      } else {
        filteredAcademies.value = academies.value.filter(company => company.name.toLowerCase().includes(search))
      }
    }

    const refreshAcademies = async () => {
      try {
        await fetchAcademies()
        filteredAcademies.value = academies.value
      } catch (error) {
        console.error('Failed to refresh academies:', error)
      }
    }

    const goToAcademy = item => {
      router.push({ name: 'academy-details', params: { id: item.id } })
    }

    const showAddDialog = () => {
      currentAcademy.value = {
        name: '',
        address: {
          street: '',
          houseNo: '',
          zipCode: '',
          city: '',
          country: '',
        },
        telephone: '',
        fax: '',
        email: '',
        taxNumber: '',
        taxNumberEu: '',
      }
      isEditDialogVisible.value = true
    }

    const showEditDialog = item => {
      currentAcademy.value = { ...item }
      isEditDialogVisible.value = true
    }

    const showDeleteConfirmationDialog = (item) => {
      currentAcademy.value = { ...item }
      isDeleteDialogVisible.value = true
    }

    const onDeleteCanceled = () => {
      currentAcademy.value = {}
      isDeleteDialogVisible.value = false
    }

    const onDeleteConfirmed = async () => {
      try {
        await deleteAcademy(currentAcademy.value.id)
        await refreshAcademies()
        isDeleteDialogVisible.value = false
      } catch (error) {
        console.error('Error while deleting academy:', error)
      }
    }

    const closeDialog = () => {
      isEditDialogVisible.value = false
    }

    const saveAcademy = async academy => {
      isLoading.value = true
      try {
        if (!academy.id) {
          await createAcademy(academy)
        } else {
          await updateAcademy(academy)
        }
        isEditDialogVisible.value = false
        await refreshAcademies()
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(refreshAcademies)

    return {
      filter,
      filteredAcademies,
      filterAcademies,
      goToAcademy,
      showAddDialog,
      showEditDialog,
      showDeleteConfirmationDialog,
      onDeleteCanceled,
      onDeleteConfirmed,
      closeDialog,
      saveAcademy,
      isAdmin,
      isEditDialogVisible,
      isDeleteDialogVisible,
      currentAcademy,
      isLoading,
      headers,
      icons: { mdiMagnify, mdiEye, mdiPencilOutline, mdiDeleteOutline },
    }
  },
}

</script>
